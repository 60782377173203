/* eslint-disable react/jsx-props-no-spreading */
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Button, { ButtonType } from '../Button/Button';
import Title from '../Title/Title';
import * as styles from './WhyUs.module.scss';

const messages = defineMessages({
    info: {
        id: 'whyUs.info',
        defaultMessage: '💪 We delivered <strong></strong> services for our customers. Become our customer today!',
    },
    title: {
        id: 'whyUs.title',
        defaultMessage: 'Why us? 👇️',
    },
    title1: {
        id: 'whyUs.title1',
        defaultMessage: 'Minimalized shop',
    },
    post1: {
        id: 'whyUs.post1',
        defaultMessage: 'For You, it is very easy to order from us. You just need to select a service, and press calculate a price. When the payment comes to us, we will start working on your order.',
    },
    title2: {
        id: 'whyUs.title2',
        defaultMessage: 'High quality service',
    },
    post2: {
        id: 'whyUs.post2',
        defaultMessage: 'All services on this page will give the best quality and price for You, compared to our competitors. If You found somewhere cheaper - lets just chat, and I\'m sure we\'ll find out best solution for you. It is wisely to choose us.',
    },
    title3: {
        id: 'whyUs.title3',
        defaultMessage: 'Track your order',
    },
    post3: {
        id: 'whyUs.post3',
        defaultMessage: 'Our system tracks order. When You have ordered our service, you can check how it\'s going on yours order. Just simply write our order ID on "Tracking" tab, and we will give you all your order information anytime.',
    },
    title4: {
        id: 'whyUs.title4',
        defaultMessage: 'Discount codes',
    },
    post4: {
        id: 'whyUs.post4',
        defaultMessage: 'Our system tracks order. When You have ordered our service, you can check how it\'s going on yours order. Just simply write our order ID on "Tracking" tab, and we will give you all your order information anytime.',
    },
});

const WhyUs = () => {
    const { formatMessage } = useIntl();
    const [clients, setClients] = useState(0);

    const getClients = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/feedback/clients`).then(({ data }) => setClients(data.clients)).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        getClients();
    }, []);

    return (
        <div id="why-us" className={styles.container}>
            <Title text={messages.title} />
            <div className={styles.boxContainer}>
                <div className={styles.box}>
                    <h3 className={styles.subtitle}>
                        <i className="fa fa-credit-card" aria-hidden="true" />
                        {formatMessage(messages.title1)}
                    </h3>
                    <p className={styles.post}>{formatMessage(messages.post1)}</p>
                </div>
                <div className={styles.box}>
                    <h3 className={styles.subtitle}>
                        <i className="fa fa-smile-o" aria-hidden="true" />
                        {formatMessage(messages.title2)}
                    </h3>
                    <p className={styles.post}>{formatMessage(messages.post2)}</p>
                </div>
                <div className={styles.box}>
                    <h3 className={styles.subtitle}>
                        <i className="fa fa-line-chart" aria-hidden="true" />
                        {formatMessage(messages.title3)}

                    </h3>
                    <p className={styles.post}>{formatMessage(messages.post3)}</p>
                </div>
                <div className={styles.box}>
                    <h3 className={styles.subtitle}>
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        {formatMessage(messages.title4)}

                    </h3>
                    <p className={styles.post}>{formatMessage(messages.post4)}</p>
                </div>
            </div>
            <Button type={ButtonType.SUBMIT} className={styles.button}>
                <FormattedMessage
                    {...messages.info}
                    values={{ strong: () => <strong>{clients}</strong> }}
                />
            </Button>
        </div>
    );
};

export default WhyUs;
