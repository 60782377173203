/* eslint-disable max-len */
import { Fragment, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { Link } from 'gatsby';
import { Link as RouterLink } from '@reach/router';
import classNames from 'classnames';
import { format } from 'date-fns';
import axios from 'axios';
import useWindowWidth from '../../hooks/useWindowWidth';
import Title from '../Title/Title';
import blogImgPath from './img/blog_img.svg';
import * as styles from './RecentBlogPosts.module.scss';

const messages = defineMessages({
    title: {
        id: 'recentBlogPosts.title',
        defaultMessage: 'Recent blog posts',
    },
    subtitle: {
        id: 'recentBlogPosts.subtitle',
        defaultMessage: 'Catch up with the latest news',
    },
});

const RecentBlogPosts = () => {
    const [list, setList] = useState([]);
    const isMobile = useWindowWidth() < 992;

    const getBlogPosts = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/blog?offset=0&limit=4`).then(({ data }) => {
            setList(data);
        }).catch((e) => console.log(e));
    };

    useEffect(() => {
        getBlogPosts();
    }, []);

    return (
        <div className={styles.container}>
            <Title text={messages.title} subtitle={messages.subtitle} align="left" />
            <div className={styles.grid}>
                <img src={blogImgPath} alt="Recent Blog posts" className={styles.img} />
                {list.length > 0 && list.map(({
                    index, text, title, slug, id, date,
                }) => (
                    <Fragment key={`${id}-${slug}`}>
                        {isMobile ? (
                            <RouterLink to={`/blog/${slug}`} className={classNames(styles.blogPost, styles[`post${index}`])}>
                                <div>
                                    <h5 className={styles.title}>{title}</h5>
                                    <h6 className={styles.subtitle}>{format(new Date(new Date(date).toISOString()), 'yyyy-MM-dd')}</h6>
                                    <p className={styles.post}>{text}</p>
                                </div>
                            </RouterLink>
                        ) : (
                            <Link to={`/blog/${slug}`} className={classNames(styles.blogPost, styles[`post${index}`])}>
                                <div>
                                    <h5 className={styles.title}>{title}</h5>
                                    <h6 className={styles.subtitle}>{date && format(new Date(new Date(date).toISOString()), 'yyyy-MM-dd')}</h6>
                                    <p className={styles.post}>{text}</p>
                                </div>
                            </Link>
                        )}

                    </Fragment>
                ))}
            </div>
        </div>
    );
};

export default RecentBlogPosts;
