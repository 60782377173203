/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';
import Title from '../Title/Title';
import * as styles from './Faq.module.scss';

const messages = defineMessages({
    title: {
        id: 'faq.title',
        defaultMessage: 'FAQ',
    },
    subtitle: {
        id: 'faq.subtitle',
        defaultMessage: 'You can look through some mostly asked questions',
    },
    firstQ: {
        id: 'faq.firstQ',
        defaultMessage: 'Is it safe for social networks?',
    },
    firstA: {
        id: 'faq.firstA',
        defaultMessage: 'We’re working in the safest way possible, while manually promoting your page just as if you were doing it yourself daily. Any mentions or increases in fans and reach will be organically achieved through our marketing efforts.',
    },
    secondQ: {
        id: 'faq.secondQ',
        defaultMessage: 'Can I split my order between different pages?',
    },
    secondA: {
        id: 'faq.secondA',
        defaultMessage: 'Unfortunately, we cannot split a single order between multiple pages. However, we propose great discounts for bulk orders. Simply reach out to us through our contact page with your goals, and we\'ll give you a quote with your discount applied.',
    },
    thirdQ: {
        id: 'faq.thirdQ',
        defaultMessage: 'Are these likes targeted?',
    },
    thirdA: {
        id: 'faq.thirdA',
        defaultMessage: 'Our standard services include worldwide likes and followers. This means you may receive users from various countries. We try to promote your page to an English speaking audience, but ultimately, we cannot control what kind of people want to like your page with this worldwide audience. We don\'t offer support for targeted fans to specific cities or from other countries at this time, though we hope to release something like this in the future.',
    },
    fourthQ: {
        id: 'faq.fourthQ',
        defaultMessage: 'Are these likes and followers added all at once?',
    },
    fourthA: {
        id: 'faq.fourthA',
        defaultMessage: 'Everything depends on the package size you buy. Our smaller packages take between 4 and 64 hours to complete. These campaigns look very natural, and you will not receive a flood of likes or followers in a short period of time.',
    },
    fifthQ: {
        id: 'faq.fifthQ',
        defaultMessage: 'Can I run a cheap-social.com campaign with another third-party campaign simultaneously?',
    },
    fifthA: {
        id: 'faq.fifthA',
        defaultMessage: 'Yes, but we don\'t recommend it. We track our progress by your total number of your likes, and if another campaign of yours is increasing your likes simultaneously, it will count towards the promotion we\'ve done for your page. However, we do over-deliver on all of our orders to account for any organic likes you\'ve received on your page during our campaign. Since we\'re manually promoting your page, we do not have software to track exactly how many likes we\'ve sent to your page. We are not responsible for replacing likes that weren\'t built by us as a result of a conflicting third-party campaign. For this reason, we recommend pausing your other campaigns before placing an order with us.',
    },
    sixthQ: {
        id: 'faq.sixthQ',
        defaultMessage: 'Does cheap-social.com offer any warranty?',
    },
    sixthA: {
        id: 'faq.sixthA',
        defaultMessage: 'The decrease of likes happens for less than 1 percent of all order. However, if you’ve faced this issue, we offer a lifetime warranty - if any of our likes or followers disappear, we will restore absolutely for free. We over-deliver on all of our packages to counter any natural drop-off.',
    },
    seventhQ: {
        id: 'faq.seventhQ',
        defaultMessage: 'Can I order one than more package?',
    },
    seventhA: {
        id: 'faq.seventhA',
        defaultMessage: 'Yes! And remember, we offer great discounts for bulk orders. Simply contacts us and together we’ll find the best solution.',
    },
    eigthQ: {
        id: 'faq.eigthQ',
        defaultMessage: 'Does cheap-social.com require my account password?',
    },
    eigthA: {
        id: 'faq.eigthA',
        defaultMessage: 'We don\'t require any password or access to your accounts. All of our likes and followers are built with no admin access necessary.',
    },
    ninethQ: {
        id: 'faq.ninethQ',
        defaultMessage: 'Where can I see the status of my order?',
    },
    ninethA: {
        id: 'faq.ninethA',
        defaultMessage: 'We will send a message to the email address specified during checkout when your service is completed. If you do not see your likes after the specified amount of time on your order, please use our contact page to send us a message.',
    },
});

const Faq = () => {
    const [active, setActive] = useState(1);
    return (
        <div id="faq" className={styles.container}>
            <Title text={messages.title} subtitle={messages.subtitle} align="left" />
            <div className={styles.grid}>
                <ul className={styles.faqs}>
                    <li
                        className={classNames({ [styles.active]: active === 1 })}
                        onClick={() => setActive(1)}
                    >
                        <FormattedMessage {...messages.firstQ} />
                    </li>
                    {active === 1 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.firstQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.firstA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 2 })}
                        onClick={() => setActive(2)}
                    >
                        <FormattedMessage {...messages.secondQ} />
                    </li>
                    {active === 2 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.secondQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.secondA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 3 })}
                        onClick={() => setActive(3)}
                    >
                        <FormattedMessage {...messages.thirdQ} />
                    </li>
                    {active === 3 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.thirdQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.thirdA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 4 })}
                        onClick={() => setActive(4)}
                    >
                        <FormattedMessage {...messages.fourthQ} />
                    </li>
                    {active === 4 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.fourthQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.fourthA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 5 })}
                        onClick={() => setActive(5)}
                    >
                        <FormattedMessage {...messages.fifthQ} />
                    </li>
                    {active === 5 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.fifthQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.fifthA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 6 })}
                        onClick={() => setActive(6)}
                    >
                        <FormattedMessage {...messages.sixthQ} />
                    </li>
                    {active === 6 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.sixthQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.sixthA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 7 })}
                        onClick={() => setActive(7)}
                    >
                        <FormattedMessage {...messages.seventhQ} />
                    </li>
                    {active === 7 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.seventhQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.seventhA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 8 })}
                        onClick={() => setActive(8)}
                    >
                        <FormattedMessage {...messages.eigthQ} />
                    </li>
                    {active === 8 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.eigthQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.eigthA} />
                            </p>
                        </li>
                    )}
                    <li
                        className={classNames({ [styles.active]: active === 9 })}
                        onClick={() => setActive(9)}
                    >
                        <FormattedMessage {...messages.ninethQ} />
                    </li>
                    {active === 9 && (
                        <li className={styles.mob}>
                            <h3><FormattedMessage {...messages.ninethQ} /></h3>
                            <p>
                                <FormattedMessage {...messages.ninethA} />
                            </p>
                        </li>
                    )}
                </ul>
                <div className={styles.answer}>
                    <div className={styles.post}>
                        {active === 1 && (
                            <>
                                <h3><FormattedMessage {...messages.firstQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.firstA} />
                                </p>
                            </>
                        )}
                        {active === 2 && (
                            <>
                                <h3><FormattedMessage {...messages.secondQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.secondA} />
                                </p>
                            </>
                        )}
                        {active === 3 && (
                            <>
                                <h3><FormattedMessage {...messages.thirdQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.thirdA} />
                                </p>
                            </>
                        )}
                        {active === 4 && (
                            <>
                                <h3><FormattedMessage {...messages.fourthQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.fourthA} />
                                </p>
                            </>
                        )}
                        {active === 5 && (
                            <>
                                <h3><FormattedMessage {...messages.fifthQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.fifthA} />
                                </p>
                            </>
                        )}
                        {active === 6 && (
                            <>
                                <h3><FormattedMessage {...messages.sixthQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.sixthA} />
                                </p>
                            </>
                        )}
                        {active === 7 && (
                            <>
                                <h3><FormattedMessage {...messages.seventhQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.seventhA} />
                                </p>
                            </>
                        )}
                        {active === 8 && (
                            <>
                                <h3><FormattedMessage {...messages.eigthQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.eigthA} />
                                </p>
                            </>
                        )}
                        {active === 9 && (
                            <>
                                <h3><FormattedMessage {...messages.ninethQ} /></h3>
                                <p>
                                    <FormattedMessage {...messages.ninethA} />
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
