import { useIntl } from 'react-intl';
import classNames from 'classnames';
import * as styles from './Title.module.scss';

const Title = ({
    text, subtitle = null, align = 'center', className,
}) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <h1 className={
                classNames(styles.title, styles[align], className, { [styles.mb]: subtitle })
            }
            >
                {formatMessage(text)}
            </h1>
            {subtitle && (
                <h6 className={classNames(
                    styles.subtitle, styles[align], className,
                )}
                >
                    {formatMessage(subtitle)}

                </h6>
            )}
        </>
    );
};

export default Title;
