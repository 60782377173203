import * as styles from './ProductLogo.module.scss';
import facebook from './img/facebook.svg';
import pinterest from './img/pinterest.svg';
import instagram from './img/instagram.svg';
import youtube from './img/youtube.svg';
import twitter from './img/twitter.svg';
import twitch from './img/twitch.svg';
import linkedin from './img/linkedin.svg';
import tumblr from './img/tumblr.svg';
import spotify from './img/spotify.svg';
import tiktok from './img/tiktok.svg';

const ProductLogo = ({ type }) => {
    const getImagePath = () => {
        switch (type.toLowerCase()) {
        case 'tiktok':
            return tiktok;
        case 'instagram':
            return instagram;
        case 'pinterest':
            return pinterest;
        case 'youtube':
            return youtube;
        case 'twitter':
            return twitter;
        case 'spotify':
            return spotify;
        case 'tumblr':
            return tumblr;
        case 'linkedin':
            return linkedin;
        case 'twitch':
            return twitch;
        case 'facebook':
            return facebook;
        default:
            return '';
        }
    };
    return (
        <div className={styles.container}>
            <img src={getImagePath()} alt={`${type} logo`} />
        </div>
    );
};

export default ProductLogo;
