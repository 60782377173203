/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'gatsby';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Title from '../Title/Title';
import * as styles from './Pricing.module.scss';
import ProductLogo from '../ProductLogo/ProductLogo';
import { getServiceName } from '../../helpers/helpers';

const messages = defineMessages({
    title: {
        id: 'pricing.title',
        defaultMessage: 'Pricing',
    },
    subtitle: {
        id: 'pricing.subtitle',
        defaultMessage: 'Select a service in order to calculate pricing',
    },
    isSale: {
        id: 'pricing.sale',
        defaultMessage: 'SALE',
    },
    productInfo: {
        id: 'pricing.productInfo',
        defaultMessage: '{name} {action} <span></span>',
    },
    from: {
        id: 'pricing.from',
        defaultMessage: 'from',
    },
});

const ListItem = ({ name, setSale }) => {
    const [list, setList] = useState([]);
    const [onSale, setOnSale] = useState(false);

    const getServices = (category) => {
        axios.get(`${process.env.GATSBY_API_URL}/api/service?category=${category}`).then(({ data }) => {
            const key = 'name';
            data.sort((a, b) => (a.oldPrice > b.oldPrice ? 1 : -1));
            const arrayUniqueByKey = [...new Map(data.map((item) => [item[key], item])).values()];
            setList(arrayUniqueByKey);
        }).catch((e) => console.log(e));
    };

    useEffect(() => {
        if (name) getServices(name);
    }, [name]);

    useEffect(() => {
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].oldPrice) {
                    setOnSale(true);
                }
            }
        }
    }, [list]);

    return (
        <div className={styles.listItem}>
            <h5 className={styles.name}>
                <ProductLogo type={name} />
                {getServiceName(name)}
                {onSale && <span><FormattedMessage id="pricing.SALE" defaultMessage="SALE" /></span>}
            </h5>
            <ul className={styles.priceList}>
                {list.length > 0 && [...new Set(list)].map(({
                    name: serviceName, price, slug, oldPrice,
                }) => (
                    <li key={serviceName}>
                        <Link to={`/buy/${name.toLowerCase()}/${slug}`}>
                            <FormattedMessage
                                {...messages.productInfo}
                                values={{
                                    name: getServiceName(name),
                                    action: serviceName,
                                    span: () => (
                                        <span>
                                            <span className={classNames({ [styles.oldPrice]: oldPrice })}>
                                                <FormattedMessage {...messages.from} />
                                                {` ${oldPrice || price}$`}
                                            </span>
                                            {oldPrice && (
                                                <span className={styles.newPrice}>{`${price}$`}</span>
                                            )}
                                        </span>
                                    ),
                                }}
                            />
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const Pricing = () => {
    const [list, setList] = useState([]);

    const getList = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/service/category`).then(({ data }) => setList(data)).catch((e) => console.log(e));
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className={styles.background}>
            <div id="pricing" className={styles.container}>
                <Title
                    text={messages.title}
                    subtitle={messages.subtitle}
                    className={styles.title}
                    align="left"
                />
                <ul className={styles.list}>
                    {list.length > 0 && list.sort((a, b) => (parseInt(a?.sortNumber || 0) > parseInt(b?.sortNumber || 0) ? 1 : -1)).map(({ name }) => (
                        <ListItem key={name} name={name} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Pricing;
