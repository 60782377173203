import { defineMessages } from 'react-intl';
import Button, { ButtonStyle, ButtonType } from '../Button/Button';
import Title from '../Title/Title';
import * as styles from './TrackOrderAdd.module.scss';

const messages = defineMessages({
    title: {
        id: 'trackOrderAdd.title',
        defaultMessage: 'Track Your Order 📦',
    },
    subtitle: {
        id: 'trackOrderAdd.subtitle',
        defaultMessage: 'Here you can track your order real-time information from our database. To view order details and delivery status, please enter your order number to access your order details.',
    },
    button: {
        id: 'trackOrderAdd.button',
        defaultMessage: 'Open order tracking',
    },
});

const TrackOrderAdd = () => (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <Title text={messages.title} subtitle={messages.subtitle} align="left" className={styles.white} />
            <Button
                type={ButtonType.LINK}
                text={messages.button}
                style={ButtonStyle.WHITE}
                className={styles.button}
                link="/track-order"
            />
        </div>
    </div>
);

export default TrackOrderAdd;
