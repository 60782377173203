import { defineMessages } from 'react-intl';
import classNames from 'classnames';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Title from '../Title/Title';
import * as styles from './Feedback.module.scss';

const messages = defineMessages({
    title: {
        id: 'feedback.title',
        defaultMessage: 'Feedback ⭐',
    },
    subtitle: {
        id: 'feedback.subtitle',
        defaultMessage: 'We are proud of our happy customer base',
    },
});

const ListItem = ({
    name, service, text, rating,
}) => (
    <li className={styles.listItem}>
        <div className={styles.starContainer}>
            <i className={classNames(styles.star, 'fa', { 'fa-star': rating >= 1, 'fa-star-o': rating < 1 })} />
            <i className={classNames(styles.star, 'fa', { 'fa-star': rating >= 2, 'fa-star-o': rating < 2 })} />
            <i className={classNames(styles.star, 'fa', { 'fa-star': rating >= 3, 'fa-star-o': rating < 3 })} />
            <i className={classNames(styles.star, 'fa', { 'fa-star': rating >= 4, 'fa-star-o': rating < 4 })} />
            <i className={classNames(styles.star, 'fa', { 'fa-star': rating >= 5, 'fa-star-o': rating < 5 })} />
        </div>
        <h5 className={styles.title}>{name}</h5>
        <h6 className={styles.subtitle}>{service}</h6>
        <p className={styles.post}>{text}</p>
    </li>
);
const Feedback = () => {
    const [list, setList] = useState([]);

    const getList = () => {
        axios.get(`${process.env.GATSBY_API_URL}/api/feedback`).then(({ data }) => setList(data)).catch((e) => {
            console.log(e);
        });
    };

    useEffect(() => {
        getList();
    }, []);
    return (
        <div className={styles.container}>
            <Title text={messages.title} subtitle={messages.subtitle} />
            <ul className={styles.list}>
                {list.length > 0 && list.slice(0, 6).map(({
                    name, rating, service, text,
                }) => (
                    <ListItem
                        key={name}
                        name={name}
                        rating={rating}
                        service={service}
                        text={text}
                    />
                ))}
            </ul>
        </div>
    );
};

export default Feedback;
