import { defineMessages } from 'react-intl';
import Seo from '../../components/Seo/Seo';
import Faq from '../../components/Faq/Faq';
import Feedback from '../../components/Feedback/Feedback';
import Layout from '../../components/layout';
import Pricing from '../../components/Pricing/Pricing';
import RecentBlogPosts from '../../components/RecentBlogPosts/RecentBlogPosts';
import TrackOrderAdd from '../../components/TrackOrderAdd/TrackOrderAdd';
import WhyUs from '../../components/WhyUs/WhyUs';

const messages = defineMessages({
    title: {
        id: 'meta.indexTitle',
        defaultMessage: 'Cheap social media services | Cheap-Social.com',
    },
    description: {
        id: 'meta.indexDescription',
        defaultMessage: 'Get more followers and likes for your social media profiles. We help you grow your social media presence overnight by increasing its organic reach.',
    },
});

const IndexPage = () => (
    <Layout isIndex>
        <Seo title={messages.title} description={messages.description} />
        <WhyUs />
        <Pricing />
        <Faq />
        <TrackOrderAdd />
        <Feedback />
        <RecentBlogPosts />
    </Layout>
);

export default IndexPage;
