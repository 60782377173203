// extracted by mini-css-extract-plugin
export var blogPost = "RecentBlogPosts-module--blogPost--BC4eV";
export var container = "RecentBlogPosts-module--container--RaciL";
export var grid = "RecentBlogPosts-module--grid--A49Jx";
export var img = "RecentBlogPosts-module--img--gU5yq";
export var post = "RecentBlogPosts-module--post--36qtI";
export var post1 = "RecentBlogPosts-module--post1--usHiQ";
export var post2 = "RecentBlogPosts-module--post2--4IsMw";
export var post3 = "RecentBlogPosts-module--post3--s2kRI";
export var post4 = "RecentBlogPosts-module--post4--hpvTz";
export var subtitle = "RecentBlogPosts-module--subtitle--OiArY";
export var title = "RecentBlogPosts-module--title--Pz71P";